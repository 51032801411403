import React from "react"
import { graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import htmlSerializer from "../utils/htmlSerializer"
import Button from "../components/shared/button"
import Banner from "../components/widgets/banner"

export const query = graphql`
  query BasicPageQuery($uid: String) {
    prismic {
      allBasic_pages(uid: $uid) {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            language
            page_title
            banner_heading
            main_content
            bottom_cta_heading
            bottom_cta_text
            bottom_cta_link {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            bottom_cta_emoji_label
            bottom_cta_emoji
          }
        }
      }
    }
  }
`
	
const BasicPageTemplate = props => {
	
  const doc = props.data.prismic.allBasic_pages.edges.slice(0,1).pop();
  if(!doc) return null;
	
  return (
    <Layout pageTitle={doc.node.page_title[0].text} lang={doc.node.language}>
      <SEO title={doc.node.meta_title || doc.node.page_title[0].text} image={doc.node.meta_image} description={doc.node.meta_description} />

      <Banner
        content={doc.node.banner_heading}
      />

      <section className={`grid-container`}>
        <div className={`grid-x`}>
          <div className={`cell`}>
            <RichText render={doc.node.main_content} htmlSerializer={htmlSerializer} />
          </div>
        </div>
      </section>

      {doc.node.bottom_cta_heading &&
        <aside className={`c-aside grid-container`}>
          <div className={`c-aside__content grid-x`}>
            <div className={`cell`}>
              <RichText render={doc.node.bottom_cta_heading} htmlSerializer={htmlSerializer} />

                <Button 
                  data-sal="slide-up" data-sal-delay="200" data-sal-duration="500"  
                  text={doc.node.bottom_cta_text}
                  link={doc.node.bottom_cta_link}
                  emoji={doc.node.bottom_cta_emoji}
                  emojiLabel={doc.node.bottom_cta_emoji_label} />            
            </div>
          </div>
        </aside>
      }

    </Layout>
  );
}
	
export default BasicPageTemplate;