import React from "react"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../../utils/htmlSerializer"

import Reveal from "react-reveal/Reveal"

const Banner = ({ content, type, headerSize, children }) => {
  return (
    <Reveal cascade effect="fadeInUp">
      <section
        className={`grid-container c-banner ${type ? `c-banner--${type}` : ``}`}
      >
        <div className={`grid-x c-banner__container`}>
          <div
            className={`cell c-banner__content ${
              type === `full` ? `medium-11` : `medium-10`
            }`}
          >
            <div
              className={`c-banner__heading ${
                headerSize ? `c-banner__heading--${headerSize}` : ``
              }`}
            >
              <RichText render={content} htmlSerializer={htmlSerializer} />
            </div>
          </div>
          {children && (
            <div className={`cell c-banner__action`}>{children}</div>
          )}
        </div>
      </section>
    </Reveal>
  )
}

export default Banner
